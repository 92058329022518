import React, { Component } from 'react';
import {
    Card,
    CardContent,
    CardMedia,
    Typography
} from '@mui/material';

import {Link} from "react-router-dom";

import './AudioList.scss';

class AudioList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            configs: props.configs,
            audioData: props.audioData,
            offlineMode: props.offlineMode
        };
    }

    render() {
        return (<div className="audio-list">
                <div className="logo"><img alt="Smartguide" title="Smartguide" src="/images/logo.png" className="logo"/></div>
                <div className="list">
                {this.state.audioData.audios.map((audio, index) => {
                    let packageTitle = (audio.type === 'package') ? <div translate="no" className="audio-package-title">{audio.parentTitle}</div> : '';
                    return (
                    <Card className="audio-item" key={index}>
                        <Link className="audio-link" to={"/audios/audio-item/" + index}>
                            {packageTitle}
                            <CardContent className="audio-content">
                                <Typography className="audio-title" component="h5" variant="h5" translate="no">
                                    {audio.title}
                                </Typography>
                            </CardContent>
                        </Link>
                        <div className="audio-image" style={{ "backgroundImage": "url(" + audio.leadingImage + ")" }}></div>
                    </Card>
                    )
                })}
                </div>
        </div>);
    };
};

export default AudioList;