import React, { Component } from 'react';

import {Link, Navigate} from "react-router-dom";
import AudioPlayer from '../../Components/AudioPlayer/AudioPlayer';
import { DBConfig } from '../../Components/Database/DBConfig';
import IndexedDB from '../../Components/Database/IndexedDB';

import {
ListItem,
        ListItemIcon,
        ListItemText,
} from '@mui/material';

import {
PlaylistPlay as PlaylistPlayIcon,
        SkipPrevious,
        SkipNext
} from '@mui/icons-material';

import {
        Map as MapIcon
} from '@mui/icons-material';

import i18n from '../../Components/i18n/i18n';

import './AudioItem.scss';

class AudioItem extends Component {

    constructor(props) {
        super(props);
        this.state = {
            audios: props.audios,
            id: window.location.href.split('/')[window.location.href.split('/').length - 1],
            data: null,
            prev: false,
            next: false,
            visited: false,
            debug: props.debug,
            offlineMode: props.offlineMode,
            hasMap: false,
            offlineMap: {}
        };
        this.setAudioId = this.setAudioId.bind(this);
        this.setAudioItemAsVisited = this.setAudioItemAsVisited.bind(this);
    }

    componentDidMount(e, q) {
        
        var hasMap = (!this.state.offlineMode) ? true : this.state.audios[this.state.id] && this.state.audios[this.state.id].map ? true : false;
        
        if (this.state.id) {
            this.setAudioId(this.state.id);
            this.setState({
                visited: true,
                hasMap: hasMap
            });
        }
    }

    setAudioId = (id) => {

        var data = this.props.audios[id] ? this.props.audios[id] : null;
        if (data === null) {
            return <Navigate to="/page-not-found"/>;
        }
        var prev = id > 0 ? true : false;
        var next = parseInt(this.state.audios.length) - 1 > id ? true : false;
        var prevId = parseInt(id) - 1;
        var nextId = (next) ? parseInt(id) + 1 : null;

        this.setState({
            data: data,
            prev: prev,
            prevId: prevId,
            next: next,
            nextId: nextId
        });

    }
    
    setAudioItemAsVisited = (e) => {
        this.props.updateVisited(this.state.id);
    }

    render() {

        if (this.props.audios[this.state.id] === undefined) {
            return <Navigate to="/page-not-found" />;
        }
        
        var audioLink = "/?audio=";
        
        if(this.state.audios[this.state.id] && this.state.audios[this.state.id].map && this.state.offlineMode){
            audioLink = "/map?audio=";
        }
        
        return (<div>
            { this.state.id && this.state.data ?
            <div className="audio-item">
                <div className="audio-item-buttons">
                    <Link to='/audios' className="play-list-back-link">
                        <ListItem button className="icon-wrapper">
                            <ListItemIcon className="icon-item"><PlaylistPlayIcon/></ListItemIcon>
                            <ListItemText className="icon-label" primary={i18n.t('menuAudioListTitle')}/>
                        </ListItem>
                    </Link>
                    {this.state.hasMap ?
                    <Link to={audioLink + this.state.id} className="play-list-back-link" center={1}>
                        <ListItem button className="icon-wrapper">
                            <ListItemIcon className="icon-item"><MapIcon/></ListItemIcon>
                            <ListItemText className="icon-label" primary={i18n.t('menuMapTitle')}/>
                        </ListItem>
                    </Link>
                    : ''}
                </div>
                <h1 translate="no">{this.state.data.title}</h1>
                <div className="leading-image"><img alt="" src={this.state.data.leadingImage}/></div>
                <AudioPlayer src={'data:audio/mp3;base64,' + this.state.data.audioFile} isVisited={(e) => this.isVisited(e)} totalAudioFiles={this.state.audios.length} setAudioItemAsVisited={this.setAudioItemAsVisited} audioId={this.state.id} controls />
                <h3 translate="no">{i18n.t("audioItemDescription")}</h3>
                <div translate="no" dangerouslySetInnerHTML={{__html: this.state.data.description}}></div>
            </div>
            : ""}
        </div>);
    }
    ;
}
;

export default AudioItem;